import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { SassyText, Section, SectionWrapper, SubTitle, Text } from "../core/commonExports"
import { graphql, useStaticQuery } from "gatsby"

const imgQuery = graphql`
  query ThreatIcons {
    threat_icons: allFile(
        filter: {
            relativeDirectory: {regex: "/threat-icons/"}
        }
    ) {
        edges {
            node {
                id
                base
                relativePath
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        height: 120
                        formats: AUTO
                        width: 120
                    )
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
  }
`

const ThreatItem = ({imgData, name}) => {
    return (
        <ThreatItemContainer>
            <ThreatIconContainer>
                <GatsbyImage 
                    image={getImage(imgData)}
                    alt={name}
                />
            </ThreatIconContainer>
            <Text fontSize="20px" mfontSize="16px" lineHeight="48px" fontWeight="600" style={{textAlign: "center"}}>{name}</Text>
        </ThreatItemContainer>
    )
}

const ProtectionFromThreats = () => {
    const {threat_icons} = useStaticQuery(imgQuery)

    const findImageData = (imgName) => {
        return threat_icons?.edges?.find(el => el.node?.relativePath?.includes(imgName))?.node
    }

    return (
      <Section background="#FFF7F1">
        <SectionWrapper>
          <SubTitle fontSize="36px" mfontSize="24px" fontWeight="bold" color="#333333" style={{textAlign: "center", marginBottom: "64px"}}>
            <SassyText>Protection from 15+ threats</SassyText>
          </SubTitle>
          <ThreatsContainer>
            <ThreatItem imgData={findImageData("fire")} name="Fire"  />
            <ThreatItem imgData={findImageData("burglary")} name="Burglary"  />
            <ThreatItem imgData={findImageData("lightning")} name="Lightning"  />
            <ThreatItem imgData={findImageData("flood")} name="Flood"  />
            <ThreatItem imgData={findImageData("riots")} name="Riots"  />
            <ThreatItem imgData={findImageData("earthquake")} name="& Many more"  />
          </ThreatsContainer>
        </SectionWrapper>
      </Section>
    )
}

const ThreatsContainer = styled.div`
    width: 100%; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    gap: 3.5rem;
    @media (max-width: 1250px) {
        gap: 1.5rem;
    }
    @media (max-width: 1080px) {
        gap: 1rem;
    }
    @media (max-width: 768px) {
        & > * {
            width: 150px;
        }
        justify-content: center;
        flex-wrap: wrap !important;
    }
`

const ThreatItemContainer = styled.div`
    margin: auto;
`

const ThreatIconContainer = styled.div`
    width: min(120px, 100%);

    @media screen and (max-width: 768px) {
        margin: auto;
        width: min(80px, 100%);
    }
`

export default ProtectionFromThreats